:root {
    --white: #ffffff;
    --black: #000000;
    --grey: #999;
    --yellow: #E5FF70;
    --blue: #402DF7;
    --red: #FF4F23;

    --primary: var(--black);
    --secondary: var(--white);

    --assertive: #ff00ff;
    --focus: #ff00ff;
    --color-error: #ff0000;
    --color-sucess: #4dc247;
}
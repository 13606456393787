.plaver-video {
    --width: 100%;
    --height: 100%;
    --fit: cover;
    
    --timer-top: 0;
    --timer-bottom: auto;
    --font-size-timer: var(--font-size-xs); //5vw;
    --font-size: var(--font-size-xs);
    --padding: var(--padding-xxs);
    --padding-y-button: var(--padding);
    
    --interface-height: 100%;
    --interface-gap: 0;    
    --opacity-interface: 0;

    //PROGRESS
    --progress-x: var(--padding);
    --progress-y: #{rem(30)};
    --progress-width: calc(100% - var(--progress-x) * 2);
    --progress-height: #{rem(5)};
    --progress-track-y: #{rem(2)};
        
    &.--hide-interface {
        --opacity-interface: 0;
        cursor: none;
    }
    &.--show-interface { --opacity-interface: 1; }
    &.--hide-interface.--pause { --opacity-interface: 1; } // En pausa se fuerza el show

    
    @media (max-width: $smartphone) {
        --padding: var(--padding-xxs);
        --padding-y-button: var(--padding-s);
        --progress-y: #{rem(50)};
        --font-size-timer: 20vw;
        --timer-top: auto;
        --timer-bottom: calc(var(--progress-y) + var(--padding-s));
    }

    @include isTouch() {
        --opacity-interface: 1 !important;
    }
}

//CONTROLS STYLES
.plaver-video {
    .unmuted,
    .muted {
        --display: block;
        position: relative;
        
        &::after {
            content: '';
            position: absolute;
            display: var(--display);
            top: .2em;
            left: 0;
            width: 100%;
            height: .9em;
            background-color: var(--color);
            mix-blend-mode: difference;
        }
    }

    [data-mute] {
        .muted { --display: none; }
        .unmuted { --display: block; }
    }

    &.--muted {
        [data-mute] {
            .muted { --display: none; }
            .unmuted { --display: block; }
        }
    }

    &.--unmuted {
        [data-mute] {
            .muted { --display: block; }
            .unmuted { --display: none; }
        }
    }

    &.--play {
        [data-play] { --display-active: block; }

        .plaver-video__screen-controls {
            [data-play] { display: none; }
        }
    }

    &.--pause {
        [data-pause] { --display-active: block; }

        .plaver-video__screen-controls {
            [data-pause] { display: none; }
        }
    }
}

.plaver-video {
    width: var(--width);
    height: var(--height);

    > video {
        @media (min-width: $smartphone) {
            width: 100%;
            height: 100%;
        }

        @media (max-width: $smartphone) {
            width: 100%;
            height: 100%;
        }

        object-fit: var(--fit);
        object-position: center center;
    }
}

.plaver-video__timer {
    position: absolute;
    bottom: var(--timer-top);
    bottom: var(--timer-bottom);
    left: 0;
    width: 100%;
    height: auto;
    padding: var(--padding);
    @include font-mono(1);
    font-size: var(--font-size-timer);
    font-variant-numeric: slashed-zero;
    text-align: center;

    @media (max-width: $smartphone) {
        display: none;
    }
}

.plaver-video__progress {
    position: absolute;
    left: var(--progress-x);
    bottom: var(--progress-y);
    width: var(--progress-width);
    height: var(--progress-height);
    
    > div {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    .track {
        background-color: var(--color);
        height: 1px;
        top: var(--progress-track-y);
    }

    .thumb {
        transform-origin: 0 0;
        background-color: var(--color);
    }
}

.plaver-video__interface {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    
    align-items: flex-end;
    justify-content: flex-end;
    flex-wrap: wrap;

    padding: 0 var(--padding);
    opacity: var(--opacity-interface);
    @include font-sans();

    .left,
    .right {
        flex: 1 0 50%;
        width: 50%;
        padding: 0 var(--interface-gap);
    }

    .right {
        display: flex;
        justify-content: flex-end;
        gap: var(--interface-gap);
    }

    .plaver-video__screen-controls {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    
        button {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            opacity: 0;
        }
    }

    @media (max-width: $smartphone) {
        .left {
            flex: 0;
            width: 0;
        }

        .right {
            width: 100%;
            flex: 1 0 100%;
            justify-content: space-around;
        }
    }

    a,
    button {
        --display-active: none;

        background-color: transparent;
        @include font-mono();
        padding: var(--padding-y-button) calc(var(--padding) * .5);
        font-size: var(--font-size);
        position: relative;
        
        &::after {
            content: '';
            position: absolute;
            display: var(--display-active);
            top: var(--padding-y-button);
            left: calc(var(--padding) * .5 + .6em);
            width: calc(100% - var(--padding) - 1.2em);
            height: .9em;
            background-color: var(--color);
            mix-blend-mode: difference;
        }
    }

    .title {
        padding: var(--padding) calc(var(--padding) * .5);
        @include font-mono();
        font-size: var(--font-size);
        text-transform: uppercase;

        .director {
            @include font-sans-bold();
        }

        @media (max-width: $smartphone) {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            text-align: center;
        }
    }

    .plaver-video__timer {
        flex: 0;
        background-color: transparent;
    }
}

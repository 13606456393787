.widget-artists {
    --color: var(--primary-color);
    --bg: transparent;
        
    @media (min-width: $smartphone) {
        --padding: var(--padding-xxs);
        --font-size: var(--font-size-s);
        --font-size-artists: var(--font-size-xl);
        --padding-artists: #{rem(225)};
        --padding-link: 0;
    }

    @media (max-width: $smartphone) {
        --padding: var(--padding-xxs);
        --font-size: var(--font-size-s);
        --font-size-artists: var(--font-size-xl);    
        --padding-artists: var(--padding-l);
        --padding-link: .32em 0;
    }
}

.widget-artists {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: var(--padding);
    
    @media (min-width: $smartphone) {
        min-height: 100vh;
    }
    
    > .title {
        position: relative;
        @include font-mono();
        font-size: var(--font-size);
        text-align: justify;
        text-align-last:  justify;
        white-space: normal;
        text-transform: uppercase;
       
        .fake {
            display: block;
            opacity: 0;
            pointer-events: none;
        }

        .anim {
            position: absolute;
            display: block;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            white-space: normal;
        }
    }

    a {
        @include basic-a();
        color: var(--color);
    }

    > video {
        --blur:0px;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
        
    }
}

.widget-artists__ul {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 0 var(--padding-artists) 0;
    margin-top: 1em;

    @include font-sans-bold(1);
    font-size: var(--font-size-artists);    
    text-transform: uppercase;
    
    @media (max-width: $smartphone) {
        display: block;
        text-align: center;
    }

    li {
        margin: .1em 1em;
    }

    li:last-of-type {
        a:hover::after {
            width: 100%;
            height: .7em;
        }
    }

    a {
        @include basic-a();
        display: inline-block;
        padding: var(--padding-link);

        &:hover {
            color: var(--primary-bg);
        }

        &:hover::after {
            content:'';
            position: absolute;
            z-index: -1;
            top:.1em;
            left:.1em;
            width: 100%;
            height: .78em;
            background-color: var(--primary-color); 
        }
    }
}

.widget-artists__images {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    pointer-events: none;

    .image {
        display: flex;
        align-items: center;
        
        > div {
            width: 100%;
            @include aspect-ratio(360, 200);
        }

        img {
            width: 100%;
            height: 100%;
            position: absolute;
            left: 0;
            top: 0;
            object-fit: cover;
        }

        &.--pos-1 {
            grid-column: 2 / 4;
            grid-row: 1;
        }
        
        &.--pos-2 {
            grid-column: 5 / 7;
            grid-row: 1;
        }
        
        &.--pos-3 {
            grid-column: 8 / 10;
            grid-row: 1;
        }

        &.--pos-4 {
            grid-column: 2 / 4;
            grid-row: 3;
        }
        
        &.--pos-5 {
            grid-column: 5 / 7;
            grid-row: 3;
        }
        
        &.--pos-6 {
            grid-column: 8 / 10;
            grid-row: 3;
        }
    }

    > div {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        padding: calc(var(--header-height) + var(--padding-m)) var(--padding-xxs) var(--padding-m);
        @include grid(10);
        --cols: 10;
        --col-size: calc((100vw - var(--padding-xxs) * 2 - var(--gap) * (var(--cols) - 1))/var(--cols));
        grid-template-rows: repeat(3, 1fr);

        &:not(.--active) {
            opacity: 0;
            visibility: hidden;
        }
    }
}

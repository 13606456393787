.block-selected-works {
    --padding-h: var(--padding-xxs);
    --padding-v: var(--padding-xl);
    --font-size: var(--font-size-s);
    
    @media (max-width: $smartphone) {
        --padding-v: var(--padding-xs);
        --font-size: var(--font-size-s);
    }
}

.block-selected-works {
    pointer-events: none;

    @media (max-width: $smartphone) {
        padding: var(--padding-v) 0 0;
        min-height: 70vh;
    }

    @media (min-width: $smartphone) {
        padding: var(--padding-v) 0;
        min-height: 110vh;
    }
    
    h2 {
        position: relative;
        padding: 0 var(--padding-h);
        @include grid(12);

        > * {
            @include font-sans-bold(1.28);
            font-size: var(--font-size);
            text-transform: uppercase;
            white-space: nowrap;
            display: block;

            @media (max-width: $smartphone) {
                grid-column: 1 / 5;
            }

            @media (min-width: $smartphone) {
                grid-column: 2 / 3;
            }
        }

        .fake {
            display: block;
            opacity: 0;
            pointer-events: none;
        }

        .anim {
            position: absolute;
            display: block;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            white-space: nowrap;
        }
    }

    .wrapper {
        position: relative;
        margin-top: var(--padding-l);
        pointer-events: none !important;
        @include z-index(999999);
        
        
        @media (min-width: $smartphone) {
            //padding: 0 calc(var(--col-size) + var(--padding-h));
        }
        
        .row {
            pointer-events: none;

            &:not(:last-child) {
                margin-bottom: var(--padding-l);
            }
            
           /* @media (max-width: $smartphone) {
                padding: 0 var(--padding-h);
                @include grid(12);
            }
            
            @media (min-width: $smartphone) {
                padding: 0 var(--gap);
                @include grid(12);

                &:not(:last-child) {
                    margin-bottom: var(--padding-xxl);
                }
            } */

            padding: 0 var(--padding-h);
            @include grid(12);

            a {
                pointer-events: all;
            }
        }
    }

    .project-card {
        @media (max-width: $smartphone) {
            &.--pos-1 {
                grid-column: 1 / 11;
            }
            
            &.--pos-2 {
                grid-column: 2 / 12;
            }
            
            &.--pos-3 {
                grid-column: 3 / 13;
            }

            video {
                //display: none !important;
            }
        }

        @media (min-width: $smartphone) {
            &.--pos-1 {
                grid-column: 1 / 7;
            }
            
            &.--pos-2 {
                grid-column: 4 / 10;
            }
            
            &.--pos-3 {
                grid-column: 7 / 13;
            }
            
            &.--pos-4 {
                grid-column: 2 / 8;
            }

            &.--pos-5 {
                grid-column: 6 / 12;
            }
        }
    }
}

@use "sass:math";

#Footer {
    --color: var(--primary-color);
    --bg: transparent;
    --padding: var(--padding-xxs);
    --font-size: var(--font-size-s);
    --height: 25vw;
    --height-holder: 100vh;
            
    @media (max-width: $smartphone) {
        --padding: var(--padding-xxs);
        --height: auto;
        --height-holder: auto;
        --font-size: var(--font-size-s);
    }
}

#Footer {
    position: relative;
    height: var(--height);
    pointer-events: none;
}

.footer__holder {
    pointer-events: none;
    position: relative;
    height: var(--height-holder);
    display: flex;
    align-items: flex-end;

    &.--disabled .footer__grid {
        pointer-events: none;
    }
}

.footer__grid {
    @include grid();
    @include font-mono(1.28);

    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    font-size: var(--font-size);
    color: var(--color);
    padding: var(--padding);
    pointer-events: all;
    
    @media (max-width: $smartphone) {
        position: relative;
        gap: var(--gap);
        overflow: hidden;
    }
    
    a {
        @include basic-a();
        color: var(--color);
    }
}

.footer__rrss {
    //pointer-events: all;
    @media (min-width: $smartphone) {
        grid-column: 1 / 2;
        grid-row: 1 / 3;
    }
    @media (max-width: $smartphone) {
        grid-column: 1 / 6;
        display: flex;
        flex-direction: column;

        a {
            display: block;
            padding: .2em 0;
        }
    }
    
    text-transform: uppercase;
}

.footer__adress {
   // pointer-events: all;

    @media (min-width: $smartphone) {
        grid-column: 3 / 7;
        grid-row: 1 / 3;
        @include grid(4);

        > li {
            grid-column: span 2;
            > span {
                white-space: nowrap;
            }
        }
    }
    @media (max-width: $smartphone) {
        grid-column: 6 / 13;
        display: flex;
        flex-direction: column;
        gap: var(--gap);
    }
    
    text-transform: uppercase;

    > li {
        > .title {
            @include font-sans-bold(1.28);
            height: 1.28em;
        }

        > span {
            display: block;
        }

        a {
            display: block;
        }
    }
}

.footer__newsletter {
    //pointer-events: all;

    @media (min-width: $smartphone) {
        grid-column: 9 / 13;
        grid-row: 1 / 2;
    }
    @media (max-width: $smartphone) {
        grid-column: 1 / 13;
        text-align: center;
        margin: 1.8em 0;
        padding: .2em 0;
    }
    
    text-transform: uppercase;
}

.footer__legales {
    //pointer-events: all;
    flex-wrap: wrap;
    @media (min-width: $smartphone) {
        grid-column: 9 / 13;
        grid-row: 1 / 2;

        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-top: calc(1.28 * 3 * 1em);
    }
    @media (max-width: $smartphone) {
        grid-column: 1 / 13;
        margin-bottom: 1.5em;

        a {
            display: block;
            padding: .2em 0;
            text-align: center;
        }
    }
}

.footer__logo {
    position: relative;
    grid-column: 1 / 13;
    opacity: 0;
    transform-origin: center center;
    pointer-events: none;
    
    @media (min-width: $smartphone) {
        margin: -2.1vw 0 -.18rem;
    }
    @media (max-width: $smartphone) {
        margin: 0 0 -.18rem;
    }

    > svg {
        fill: var(--color);
        width: 100%;
        height: auto;
        
        &:nth-child(2) {
            position: absolute;
            transform-origin: center center;
            top: 0;
            left: 0;
        }
    }
}

.footer__copy {
    position: relative;
    grid-column: 1 / 13;
    text-transform: uppercase;
    line-height: .9;
   
    @media (min-width: $smartphone) {
        > span {
            display: block;
            text-align: justify;
            text-align-last: justify;
            height: .9em;
            width: 100%;
            letter-spacing: 0.24em;
                                    
            &:first-of-type {
                margin-top: -1.7em;
            }
        }
    }
    @media (max-width: $smartphone) {
        > span {
            display: block;
            text-align: justify;
            text-align-last: justify;
            width: calc(100% + .5em);
            white-space: normal !important;
                        
            &:first-of-type {
                margin-top: -1.7em;
            }
        }
    }
}




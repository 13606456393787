.widget-contact {
    --color: var(--primary-color);
    --bg: transparent;
        
    @media (min-width: $smartphone) {
        --padding: 15vw var(--padding-xxs) 10vw;
        --font-size-adress: var(--font-size-s);
        --font-size: var(--font-size-s);
    }
    @media (max-width: $smartphone) {
        --padding: 0 var(--padding-xxs);
        --font-size-adress: var(--font-size-s);
        --font-size: var(--font-size-s);    
    }
}

.widget-contact {
    position: relative;
    @include grid();
    padding: var(--padding);

    a {
        @include basic-a();
        color: var(--color);
    }
}

.widget-contact__adress {
    @media (min-width: $smartphone) {
        grid-column: 3 / 10;
    }

    @media (max-width: $smartphone) {
        grid-column: 1 / 13; 
    }

    text-transform: uppercase;
    
    > li {
        @media (min-width: $smartphone) {
            @include grid(8);
        }
        @media (max-width: $smartphone) {
            @include grid(12);
        }
        margin-bottom: var(--padding-l);

        > .title {
            @media (min-width: $smartphone) {
                grid-column: 1 / 2;
            }
            @media (max-width: $smartphone) {
                grid-column: 1 / 3;
            }

            @include font-sans-bold(1);
            font-size: var(--font-size-adress);
        }

        > .info {
            @media (min-width: $smartphone) {
                grid-column: 2 / 9;
            }
            @media (max-width: $smartphone) {
                grid-column: 3 / 13;
            }

            > span {
                display: block;
                @include font-mono(1);
                font-size: var(--font-size-adress);
            }
        }
    }
}

.widget-contact__team {
    @media (min-width: $smartphone) {
        grid-column: 4 / 12;
        margin-top: var(--padding-l);
    }
    @media (max-width: $smartphone) {
        grid-column: 1 / 13; 
    }
    
    @include font-mono(1.28);
    font-size: var(--font-size);
       
    > li {
        @media (min-width: $smartphone) {
            @include grid(9);

            .title {
                display: flex;
                align-items: center;
                grid-column: 1 / 3;
                text-transform: uppercase;
            }
            .role {
                grid-column: 3 / 5;
                text-transform: uppercase;
            }
            .email {
                grid-column: 5 / 10;
            }
        }
        @media (max-width: $smartphone) {
            @include grid(12);

            .title {
                display: flex;
                align-items: center;
                grid-column: 1 / 6;
                grid-row: 1 / 2;
                text-transform: uppercase;
            }
            .role {
                font-size: var(--font-size-xs);
                grid-column: 1 / 12;
                grid-row: 2 / 3;
                text-transform: uppercase;
                margin-bottom: 1em;
                padding-left: 1.45em;
            }
            .email {
                grid-column: 6 / 13;
                grid-row: 1 / 2;
            }
        }        
    }
}
.widget-about {
    --color: var(--primary-color);
    --bg: transparent;
        
    @media (min-width: $smartphone) {
        --padding: var(--padding-xxs);
        --font-size: var(--font-size-xxl);
    }
    @media (max-width: $smartphone) {
        --padding: var(--padding-xxs);
        --font-size: var(--font-size-m);    
    }
}

.widget-about {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    justify-content: center;
    align-items: center;
    padding: var(--padding);
    min-height: 100vh;

    > .icon {
        position: absolute;
        top: -10%;
        left: -10%;
        width: 120%;
        height: 120%;

        > svg {
            fill: var(--color);
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
        }

        &.--diff {
            mix-blend-mode: difference;
        }

        &.--black {
            > svg {
                fill: var(--black);
            }
        }
    }
    
    > .text {
        grid-column: 1 / 2;
        grid-row: 1 / 2;

        position: relative;
        @include font-sans-bold();
        font-size: var(--font-size);
        text-align: center;
        white-space: normal;
        text-transform: uppercase;
       
        .fake {
            display: block;
            opacity: 0;
            pointer-events: none;
        }

        .anim {
            position: absolute;
            display: block;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            white-space: normal;
        }
    }

    > .text-back {
        grid-column: 1 / 2;
        grid-row: 1 / 2;

        position: relative;
        @include font-mono();
        font-size: var(--font-size);
        text-align: justify;
        text-align-last:  justify;
        white-space: normal;
        text-transform: uppercase;
        color: #000;
       
        
        .anim {
            display: block;
            white-space: normal;
        }
    }

    a {
        @include basic-a();
        color: var(--color);
    }

    > video {
        --blur:0px;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
        filter: blur(var(--blur));
    }
}

.block-project-list {
    --padding-h: var(--padding-xxs);
    --padding-v-top: #{rem(300)};
    --padding-v-bottom: var(--padding-xl);
    --font-size: var(--font-size-s);
    --font-size-filters: var(--font-size-s);
    --font-size-sup: var(--font-size-xxs);
    --color: var(--primary-color);
    
    @media (max-width: $smartphone) {
        --padding-v-top: #{rem(100)};
        --padding-v-bottom: 0;
        --font-size-sup: var(--font-size-xxs);
        --font-size-filters: var(--font-size-xxs);
        --font-size: var(--font-size-xs);
    }
}

.block-project-list {
    padding: var(--padding-v-top) 0 var(--padding-v-bottom);
    min-height: 80vh;

    a {
        @include basic-a();
        color: var(--color);
    }

    .filters {
        margin-bottom: var(--padding-m);
        display: flex;
        justify-content: center;
        gap: var(--padding-xs);
        padding: 0 var(--padding-h);

        a {
            position: relative;
            @include font-sans-bold();
            font-size: var(--font-size-filters);
            text-transform: uppercase;
            
            sup {
                @include font-mono();
                font-size: var(--font-size-sup);
            }

            
            &[aria-current="page"] {
                background-color: var(--primary-color);
                color: var(--primary-bg);
            }

            .fake {
                opacity: 0;
                pointer-events: none;
            }

            .anim {
                position: absolute;
                display: block;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                white-space: normal;
            }
        }
    }

    ul {

        @media (max-width: $smartphone) {
            display: grid;
            grid-template-columns: 1fr 1fr;
        }

        li {
            a {
                @include font-mono(.9);
                font-size: var(--font-size);
                text-transform: uppercase;
                position: relative;
                transform-origin: left center;

                .bg-image {
                    @include z-index(0);
                    position: absolute;
                    left: 0;
                    top: 0;
                    height: 100%;
                    width: 100%;
                    overflow: hidden;
                    opacity: 0;
                    //transform: translate3d(-100%,0,0);

                    @media (max-width: $smartphone) {
                        position: relative;
                        height: auto;
                        opacity: 1;
                        @include aspect-ratio(16,9);
                        transform: translate3d(0,0,0);
                        margin-bottom: var(--padding-xxs);
                    }
                }

                .bg-image figure {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;

                    @media (min-width: $smartphone) {
                        //transform: translate3d(80%,0,0);
                    }
                }

                .bg-image img,
                .bg-image video {
                    position: absolute;
                    left: 0;
                    top: 0%;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;

                    @media (min-width: $smartphone) {
                        filter: blur(5px);
                        top: -50%;
                        height: 200%;
                    }
                }

                p {
                    @include z-index(1);
                    position: relative;
                }
            }

            .name {
                .director {
                    @include font-sans-bold(.9);
                }

                @media (max-width: $smartphone) {
                    .id {
                        display: none;
                    }
                }
            }

            @include isCursor() {
                a:hover {
                    @include z-index(1);
                    transform: scale3d(1.4, 1.4, 1);
                }
            }
        }
    }

    @media (max-width: $smartphone) {
        .filters {
            gap: var(--padding-xxs);
            
            a {
                @include font-sans-bold(1.28);

                sup {
                    top: -.3em;
                }
            }
        }

        ul {
            li {
                a {
                    padding: var(--padding-xs) var(--padding-h);
                    display: flex;
                    flex-wrap: wrap;
    
                    > p:not(:last-child) {
                        margin-bottom: var(--padding-xxxs);
                    }


                    .bg {
                        background: var(--primary-bg);
                    }
                }

                .bg-image {
                    flex: 0 0 100%;

                    video {
                        display: none !important;
                    }
                }
                
                .name {
                    flex: 0 0 100%;
                    width: 100%;
                    
                    > span {
                        line-height: 1.3;
                        display: block;
                    }

                    .spacer {
                        display: none;
                    }
                }

                /*.year,*/
                .category {
                    flex: 0 0 100%;
                }

                .category {
                    text-align: right;
                }
            }
        }
    }

    @media (min-width: $smartphone) {
        ul {
            li {
                a {
                    padding: var(--padding-xxs) var(--padding-h);
                    @include grid(12);
                }

                .name {
                    grid-column: 1 / 8;
                }

                .year {
                    grid-column: 8 / 12;
                }

                .category {
                    grid-column: 12 / 13;
                    text-align: right;
                }
            }
        }
    }
}

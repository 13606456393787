$z-index-bg: 0;
$z-index-bg-video: 0;
$z-index-bg-canvas-back: 0;
$z-index-wrap: 3;
$z-index-header: 5;
$z-index-bg-canvas-front: 12;


$z-index-3D: 1;

$z-index-cookies: 3;
$z-index-goto-main: 4;
$z-index-progress: 5;
$z-index-categories: 6;
$z-index-footer: 10;
$z-index-cursor: 10;

$z-index-scrollbar: 11;
$z-index-scroll-top: 11;
$z-index-interface: 11;
$z-index-windows: 12;
$z-index-sidemenu: 12;
$z-index-loader: 14;
$z-index-tweakpane: 15;

$z-index-reserva: 6;

.widget-home {
    --color: var(--primary-color);
    --bg: transparent;
    --width-inside: calc(100% - var(--padding) * 2);
        
    @media (min-width: $smartphone) {
        --padding: var(--padding-xxs);
        --font-size: var(--font-size-s);
        --width-image: 100vh;
        --height-image: 100vh;
    }
    @media (max-width: $smartphone) {
        --padding: var(--padding-xxs);
        --font-size: var(--font-size-s);  
        --width-image: 100vh;
        --height-image: 50vh;  
    }
}

.widget-home__ul.__scroll-infinity-axis-y {
    --width-image: 100vw;
}

.widget-home {
    position: relative;
    width: 100vw;
    height: calc(var(--vh, 1vh) * 100);

    .project-card-small {
        display: block;
        top: 0;
        left: 0;
    }

    > video {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}

.widget-home__ul {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 0;

    li {
        position: relative;
        flex: 1 0 var(--width-image);
        width: var(--width-image);
        height: var(--height-image);
        overflow: hidden;

        a {
            @include basic-a();
            cursor: pointer;
            &[data-cursor-follow] {
                transition: none !important;
            }
        }
    }
}

.widget-home__article {
    position: absolute;
    left: var(--padding);
   
           
    @media (min-width: $smartphone) {
        display: flex;
        flex-direction: row;
        gap: var(--gap);
        bottom: var(--padding-l);
        //bottom: calc(var(--padding) + var(--font-size) * 2 + var(--padding-l) * 2);
    } 

    @media (max-width: $smartphone) {
        @include grid(12);
        bottom: var(--padding-s);
       //bottom: calc(var(--padding) * 2);
    } 

    .id {
       /* @media (min-width: $smartphone) {
            grid-column: 1/2;
            grid-row: 1/2;
        }*/ 
    
        @media (max-width: $smartphone) {
            grid-column: 1/5;
            grid-row: 2/3;
        } 
        
        @include font-mono();
        font-size: var(--font-size);        
    }

    .title {
        /*@media (min-width: $smartphone) {
            grid-column: 5/9;
            grid-row: 1/2;
        } */
    
        @media (max-width: $smartphone) {
            grid-column: 5/12;
            grid-row: 2/3;
            margin-bottom: .2em;
        } 
        
        @include font-mono();
        font-size: var(--font-size);
        text-transform: uppercase;
    }

    .director {
        /*@media (min-width: $smartphone) {
            grid-column: 2/5;
            grid-row: 1/2;
        } */
    
        @media (max-width: $smartphone) {
            grid-column: 1/12;
            grid-row: 1/2;
            margin-bottom: .2em;
        } 
        
        @include font-sans-bold();
        font-size: var(--font-size);
        text-transform: uppercase
    }

    .description {
        @media (min-width: $smartphone) {
            grid-column: 2/5;
            grid-row: 2/3;
        } 
    
        @media (max-width: $smartphone) {
            grid-column: 4/13;
            grid-row: 2/3;
        } 
        
        @include font-mono();
        font-size: var(--font-size);
        text-transform: uppercase;
        min-height: 9em;
        margin-bottom: var(--padding-m);
        overflow: hidden;
        display: none;
    }

    .link-see {
        @media (min-width: $smartphone) {
            grid-column: 3/4;
            grid-row: 3/4;
        } 
    
        @media (max-width: $smartphone) {
            grid-column: 1/12;
            grid-row: 3/4;
            text-align: left;
        } 
        
        @include basic-a();
        @include font-mono();
        font-size: var(--font-size);
        color: inherit;
        text-align: center;
    }

    .link-prev {
        @media (min-width: $smartphone) {
            grid-column: 2/3;
            grid-row: 3/4;
        } 
    
        @media (max-width: $smartphone) {
            grid-column: 2/4;
            grid-row: 3/4;
        } 
        
        @include basic-a();
        @include font-mono();
        font-size: var(--font-size);
        text-transform: uppercase;
        color: inherit;
        background: transparent;
        padding: 0;
        text-align: left;
        display: none;
    }

    .link-next {
        @media (min-width: $smartphone) {
            grid-column: 4/5;
            grid-row: 3/4;
        } 
    
        @media (max-width: $smartphone) {
            grid-column: 10/12;
            grid-row: 3/4;
        } 
        
        @include basic-a();
        @include font-mono();
        font-size: var(--font-size);
        text-transform: uppercase;
        color: inherit;
        background: transparent;
        padding: 0;
        text-align: right;
        display: none;
    }
}

.widget-home__footer {
    position: absolute;
    left: var(--padding);
    bottom: var(--padding);
    width: var(--width-inside);
    pointer-events: none;

    @include font-mono();
    font-size: var(--font-size);    
    text-transform: uppercase;
        
    @media (min-width: $smartphone) {
        > span {
            display: block;
            text-align: justify;
            text-align-last: justify;
            height: .9em;
            width: 100%;
            letter-spacing: 0.13em;
                                    
            &:first-of-type {
                margin-top: -1.7em;
            }
        }
    }
    @media (max-width: $smartphone) {
        > span {
            display: block;
            text-align: justify;
            text-align-last: justify;
            width: calc(100% + .5em);
            white-space: normal !important;
                        
            &:first-of-type {
                margin-top: -1.7em;
            }
        }
    }
}
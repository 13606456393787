
.project-card {
    --padding: var(--padding-s);
    --font-size: var(--font-size-s);
    
    @media (max-width: $smartphone) {
        --padding: var(--padding-xxs);
        --font-size: var(--font-size-s);
    }
}

.project-card {
    @include aspect-ratio(16, 9);
    @include basic-a();
    color: var(--primary-color);
    position: relative;
    
    .overlay,
    figure {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
    }
    
    img,
    video {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .overlay {
        @include font-mono();
        text-transform: uppercase;
        font-size: var(--font-size);
        padding: var(--padding);
        display: flex;
        align-items: flex-end;
        background-color: rgba(0, 0, 0, .3);

        > div {
            width: 100%;
        }

        span {
            display: block;
            white-space: nowrap;
        }

        .name {
            padding-left: var(--padding-s);
        }
    }
    
    // @media (max-width: $smartphone) {
    // }
    
    // @media (min-width: $smartphone) {
    // }
}

@use "sass:math";

:root {
  --y-header: 0;

  --padding-xxxl: #{math.div(120px, 16px) * 1rem};
  --padding-xxl: #{math.div(90px, 16px) * 1rem};
  --padding-xl: #{math.div(60px, 16px) * 1rem};
  --padding-l: #{math.div(50px, 16px) * 1rem};
  --padding-m: #{math.div(30px, 16px) * 1rem};
  --padding-s: #{math.div(20px, 16px) * 1rem};
  --padding-xs: #{math.div(15px, 16px) * 1rem};
  --padding-xxs: #{math.div(10px, 16px) * 1rem};
  --padding-xxxs: #{math.div(8px, 16px) * 1rem};
  --padding-xxxxs: #{math.div(4px, 16px) * 1rem};

  --header-height: #{math.div(120px, 16px) * 1rem};
  --scrollbar-height: #{math.div(40px, 16px) * 1rem};

  --cols: 12;
  --gap: var(--padding-s);
  --col-size: calc((100vw - var(--padding-xxs) * 2 - var(--gap) * (var(--cols) - 1))/var(--cols));
  
  @media (max-width: $smartphone) {
    --gap: var(--padding-xxs);
  }
}

@mixin grid($cols: 0) {
  display: grid;
  column-gap: var(--gap);

  @if $cols > 0 {
    grid-template-columns: repeat($cols, var(--col-size));
  }
  @else {
    grid-template-columns: repeat(var(--cols), var(--col-size));
  }
}

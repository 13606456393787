.block-artist-billboard {
    --padding-h: var(--padding-xxs);
    --padding-v: var(--header-height);
    --font-size: var(--font-size-s);
    --font-size-title: 15vw;
    
    @media (max-width: $smartphone) {
        --font-size-title: var(--font-size-xxl);
    }
}

.block-artist-billboard {
    position: relative;
    padding: var(--padding-v) var(--padding-h) 0;
    @include grid();
        
    @media (max-width: $smartphone) {
        overflow: hidden;
    }

    h1 {
        grid-column: 1 / 13;
        @include font-sans-bold(.8);
        letter-spacing: -.05em;
        font-size: var(--font-size-title);
        text-transform: uppercase;
        text-align: center;

        @media (min-width: $smartphone) {
            padding: .1em 0 .05em;
        }

        @media (max-width: $smartphone) {
            padding: 1.4em 0 .05em;
        }
    }

    .description {
        position: relative;
        grid-column: 1 / 13;
        
        p {
            @include font-mono();
            font-size: var(--font-size);
            text-transform: uppercase;
            margin: 1em 0;

            &:first-of-type {
                margin-top: 0;
            }

            &:last-of-type {
                margin-bottom: 0;
            }
        }

        .fake {
            display: block;
            opacity: 0;
            pointer-events: none;
        }

        .anim {
            position: absolute;
            display: block;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            white-space: nowrap;
        }
    }
}

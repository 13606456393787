#BGVideo {
    --blur: 0;
    --scale: 1;

    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
        
    @include z-index($z-index-bg-video);

    opacity: 0;
    pointer-events: none;

    filter: blur(var(--blur));
    transform: scale3d(var(--scale), var(--scale), var(--scale));

    video {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center center;
    }
}
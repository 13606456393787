.plaver-video-full {
  position: fixed;

  --width-button: 100px;
  --font-size-timer: 20.5vw;
  --color: var(--secondary);

  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: black;
  
  > video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center center;
  }
}

//TIMER
.plaver-video-full__timer {
  position: absolute;
  top:0;
  left:0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  @include font-sans(1);
  font-size: var(--font-size-timer);
  color: var(--color);
  background-color: rgba(0,0,0,.45);
}

.plaver-video-full__interface {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  padding: 0 var(--padding-m);

  display: flex;
  align-items: center;
  justify-content: center;

  > span {
    flex: 0;
    @include font-sans(1);
    font-size: var(--font-size-base);
    color: var(--color);
  }

  > .plaver-video-full__progress {
    flex: 1;
  }
}

//PROGRESS
.plaver-video-full__progress {
  position: relative;

  cursor: pointer;
  height: 8px;

  &:before {
    @include pseudo-element-absolute();
    top: -40px;
    width: 100%;
    height: calc(100% + 80px);
  }

  > .track,
  > .thumb {
    position: absolute;
    top:1px;
    left:0;
    width: 100%;
    height: 8px;
  }

  >.thumb {
    top:calc(1px + 3px);
    height: 2px;
  }


  > .thumb {
    background: var(--color);
  }

  > .track {
    border-top: 1px dashed var(--color);
    border-bottom: 1px dashed var(--color);
  }

  > .thumb {
    transform: scaleX(0);
    transform-origin: 0 0;
  }
}

//plaver-video-full__button
.plaver-video-full__controls {
  flex: 0 0 80px;
  height: calc(var(--padding-l) * 2 + var(--font-size-base));

  &.--play {
    .__play,
    .__muted {
      display: none;
    }
    .__pause {
      display: block;
    }
  }

  &.--muted {
    .__play,
    .__pause {
      display: none;
    }
    .__muted {
      display: block;
    }
  }

  &.--pause {
    .__pause,
    .__muted {
      display: none;
    }
    .__play {
      display: block;
    }
  }
}

.plaver-video-full__controls > button {
  position: relative;

  width: 100%;
  height: 100%;
  background: transparent;
  padding: 0;
  border: none;

  &:not(.__full) {
    &:after {
      @include pseudo-element-absolute();
      top: -100vh;
      left: -100vw;
      width: 200vw;
      height: 200vh;
    }
  }

  @include basic-a();
  @include font-sans(1);
  font-size: var(--font-size-base);
  color: var(--color);
}


///SHOW

.plaver-video-full {
  cursor: none;

  * {
    cursor: none;
  }

  > div {
    opacity: 0;
  }

  .__showInterface & {
    > div {
      opacity: 1;
    }

    cursor: auto;

    * {
      cursor: auto;
    }
  }
  .__showInterfaceForce & {
    > div {
      opacity: 1;
    }


    cursor: auto;

    * {
      cursor: auto;
    }
  }
}

@media (max-width: $smartphone) {
  .plaver-video-full__controls {
    flex: 0 0 180px;
    display: flex;
  }
  .plaver-video-full__controls > button {
    width: 60px;

    &.__full {
      width: 120px;
    }
  }
  .plaver-video-full {
    height: calc(var(--vh, 1vh) * 100);

    > .plaver-video-full__interface {
      opacity: 1;
    }
  }

  .plaver-video-full__interface {
    padding: 0 var(--padding-xxs);
  }
}
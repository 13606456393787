
.project-card-small {
    --height: 100%;
    --mod: 1;
    --font-size: var(--font-size-xs);
    
    @media (min-width: $smartphone) {
        --mod: 1;
        --width-image: 110%;
        --height-image: 100%;
    }

    @media (max-width: $smartphone) {
        --mod: 1;
        --width-image: 100%;
        --height-image: 130%;
    }
}

.project-card-small {
    @include basic-a();
    
    position: relative;
    width: var(--width-image);
    height: var(--height);
    color: var(--primary-color);
    
    .holder {
        position: relative;
        width: 100%;
        height: var(--height);
        display: block;
    }
    
    figure {
        position: relative;
        width: var(--width-image);
        height: var(--height-image);
        transform-origin: center center;

        @media (max-width: $smartphone) {

        }

        @media (max-width: $smartphone) {

        }
    }

    img,
    video {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .info {
        position: absolute;
        top:calc(var(--height-image) - 1.5em);
        left: #{rem(80)};
        text-transform: uppercase;
        font-size: var(--font-size);
        pointer-events: none;
        opacity: 0;

        span {
            position: relative;
            display: block;
        }

        .director {
            @include font-sans-bold();
            font-size: var(--font-size);

            > span {
                display: block;
            }
        }

        .id, .title {
            @include font-mono();
        }

        .description {
            visibility: hidden;
        }
    }
    
    // @media (max-width: $smartphone) {
    // }
    
    // @media (min-width: $smartphone) {
    // }
}

@use "sass:math";

:root {
    --font-sans: 'AreaNormal', sans-serif;
    --font-sans-bold: 'AreaNormal', sans-serif;
    --font-mono: 'Soehne Mono', sans-serif;

    --font-size-xxxl: #{math.div(90px, 16px) * 1rem};
    --font-size-xxl: #{math.div(52px, 16px) * 1rem};
    --font-size-xl: #{math.div(24px, 16px) * 1rem};
    --font-size-l: #{math.div(18px, 16px) * 1rem};
    --font-size-base: #{math.div(14px, 16px) * 1rem};
    --font-size-s: #{math.div(12px, 16px) * 1rem};
    --font-size-xs: #{math.div(10px, 16px) * 1rem};
    --font-size-xxs: #{math.div(8px, 16px) * 1rem};

    --zoom: 1;
    --line-height-sans: 0.9;
    --letter-spacing: 0;

    --min-font-size: 10px;
    --max-font-size: 48px;
    --font-size: clamp(var(--min-font-size), 1.1111vw * var(--zoom), var(--max-font-size));
    font-size: var(--font-size);
    
    @media (max-width: $smartphone) {
        --font-size: 16px;
    }
}

@mixin font-sans($line-height:0) {
    font-family: var(--font-sans);
    font-weight: normal;
    @if $line-height > 0 { line-height: $line-height; }
    @else { line-height: var(--line-height-sans);  }
}

@mixin font-sans-bold($line-height:0) {
    font-family: var(--font-sans-bold);
    font-weight: 900;
    @if $line-height > 0 { line-height: $line-height  }
    @else { line-height: var(--line-height-sans);  }
}

@mixin font-mono($line-height:0) {
    font-family: var(--font-mono);
    font-weight: normal;
    font-style: normal;
    font-variant-numeric: slashed-zero;
    @if $line-height > 0 { line-height: $line-height  }
    @else { line-height: var(--line-height-sans);  }
}

@mixin font-sans-italic($line-height:0) {
    font-family: var(--font-sans);
    font-weight: 200;
    font-style: italic;
    @if $line-height > 0 { line-height: $line-height  }
    @else { line-height: var(--line-height-sans);  }
}

@use "sass:math";

#Header {

    --size-canvas: calc(var(--logo-width) * .9);
    --top-canvas: calc(var(--size-canvas) * -0.65);
              
    --color: var(--primary-color);
    --bg: transparent;
    
    @media (min-width: $smartphone) {
        --logo-width: #{rem(180)};
        --padding: calc(var(--top-canvas) * -.65);
        --font-size: var(--font-size-s);
        --link-margin: var(--padding-m);
        --left-canvas: #{rem(-6)};
    }

    @media (max-width: $smartphone) {
        --logo-width: #{rem(100)};
        --padding: calc(var(--top-canvas) * -.65);
        --font-size: var(--font-size-s);
        --link-margin: 0;
        --left-canvas: #{rem(-2)};
    }
}

#Header {
    position: fixed;
    @include z-index($z-index-header);
    width: 100%;
    right: 0;
    top: 0;
    
    display: flex;
    justify-content: center;
    align-items: flex-end;

    color: var(--color);
    background-color: var(--bg);
    padding: var(--padding) 0 0;

    transform: translate3d(0, var(--y-header), 10px);

    .logo {
        @include basic-a();
        display: block;
        position: relative;
        width: var(--logo-width);
                
        @media (max-width: $smartphone) {
            
        }

        svg {
            position: relative;
            top: 0;
            left: 0;
            width: 100%;
            height: auto;
            fill: var(--color);
            transform-origin: center center;

            @media (min-width: $smartphone) {
                height: 100%;
            }
        
            @media (max-width: $smartphone) {
                height: auto;
            }
        }

        canvas {
            display: block;
            position: absolute;
            top: var(--top-canvas);
            left: var(--left-canvas);
            width: var(--size-canvas);
            height: var(--size-canvas);
        }
    }
}

#Header nav {
    position: relative;
    width: 100%;
    height: auto;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;

    @media (max-width: $smartphone) {
        justify-content: space-between;
    }
    
    @media (max-width: $smartphone) {
        padding: 0 var(--padding-xxs);
    }

    a:not(.logo) {
        position: relative;
        display: block;
        @include basic-a();
        @include font-mono(1);
        font-size: var(--font-size);
        color: var(--color);
        padding: 0;
        margin: 0 var(--link-margin);
        text-transform: uppercase;

        &:last-of-type {
            justify-self: flex-end;
        }

        &[aria-current="page"]::after {
            position: absolute;
            bottom: 0;
            right: -1em;
            content: '←';
        }
        &[aria-current="page"]::before {
            position: absolute;
            bottom: 0;
            left: -1em;
            content: '→';
        }
    }
}

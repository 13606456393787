body > canvas {
  position: fixed;
  top:0;
  left:0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

#Interface__Canvas {  @include z-index($z-index-interface); }
#CanvasBack {  
  @include z-index($z-index-bg-canvas-back);
  filter: brightness(0);

  .--canvas-mid & {
    @include z-index(2);
  }
}

#CanvasFront {    
  .--canvas-top & {
    @include z-index($z-index-bg-canvas-front);
    mix-blend-mode: difference;
  }

  .--canvas-bottom & {
    @include z-index($z-index-bg-canvas-back);
  }

  .--canvas-mid & {
    @include z-index($z-index-bg-canvas-front);
    mix-blend-mode: difference;
  }
}
